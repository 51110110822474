<template>
    <b-overlay :show="isLoading">
{{aggridKey}}
        <div v-if="inCard" :key="aggridKey" class="card ">
            <div v-if="showCardHeader" class="card-header row">
                <slot name="datatable_header_title"></slot>
                <div class="d-flex col-sm-12">
                    <div v-if="showPagination" class="mr-2">
                        <select v-model="pageSize" class="form-control" required>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="5000">5000</option>
                        </select>
                    </div>
                    <div class="allBoutons BoutonsEntete">
                        <button v-if="showDeleteButton" :class="showDelete?'btn btn-sm btn-danger':'btn'"
                                @click.prevent="toggleDelete">
                            <i class="fa fa-trash"></i> <span>Elements Archivé  </span>
                        </button>
                        <button v-if="showExport" class="btn btn-sm btn-warning" @click.prevent="onBtExport">
                            <i class="fas fa-file-download"></i> <span>Exporter</span>
                        </button>
                        <button v-if="showActu" class="btn btn-sm btn-secondary" @click.prevent="redraw">
                            <i class="fa fa-refresh"></i> <span>Actualiser  </span>
                        </button>

                        <slot name="header_buttons"></slot>

                        <template v-for="(filtre,key) in filtreEntete">
                            <button
                                :class="actualFiltre==getNameForFiltre(filtre)?'btn btn-sm btn-outline-primary':'btn btn-sm btn-outline-dark'"
                                @click.prevent="()=>toggleFiltre(getNameForFiltre(filtre))">
                                <i class="fa fa-filter"></i><span> Filtre {{ getTotalSelectForFiltre(filtre) }}</span>
                            </button>

                        </template>
                        <button class="btn">{{ dataCount }} Ligne(s)</button>
                    </div>


                </div>
                <div class="col-sm-12">
                    <template v-for="(filtre,key) in filtreEntete">

                        <div v-if="actualFiltre==getNameForFiltre(filtre)" class="elementFiltre">
                            <b-overlay :show="!Object.keys(filtre).includes('donnees')">
                                <button v-if="showDeleteButton" class="btn btn-sm " @click.prevent="toggleDelete">
                                    <span
                                        style="color:#389ccc"> Filtre de {{ getTotalSelectForFiltre(filtre) }}   </span>
                                </button>
                                <template v-for="(items,key1)  in filtre.donnees">

                                    <button v-if="isSelectElementEntete(filtre.field,items.id)"
                                            :key='`Select-${key}-${key1}`' v-b-tooltip.hover
                                            class=" btn btn-sm btn-outline-primary"
                                            style="margin:0 5px"
                                            @click.prevent="toggleElementEntete(filtre.field,items.id)"
                                    >

                                        <span><i class="far fa-check-square"></i> {{
                                                items.libelle.toLowerCase()
                                            }}</span>


                                    </button>
                                    <button v-else :key='`UnSelect-${key}-${key1}`' v-b-tooltip.hover
                                            class="btn btn-sm"
                                            style="margin:0 5px"
                                            @click.prevent="toggleElementEntete(filtre.field,items.id)">
                                           <span>
                                               <i class="far fa-square"></i> {{ items.libelle.toLowerCase() }}
                                           </span>


                                    </button>

                                </template>
                            </b-overlay>


                        </div>
                    </template>
                </div>
            </div>

            <div class="card-body overflow-auto">
                <slot name="beforeTable"></slot>
                <ag-grid-vue
                    :id="id"
                    :key="tableKey"
                    :autoHeight="autoHeight"
                    :class="className"
                    :columnDefs="colonnes"
                    :detailCellRenderer="detailCellRenderer"
                    :detailCellRendererParams="detailCellRendererParams"
                    :detailRowAutoHeight="detailRowAutoHeight"
                    :domLayout="domLayout"
                    :excelStyles="excelStyles"
                    :getRowId="getRowId"
                    :getRowStyle="getRowStyle"
                    :getServerSideGroupKey="getServerSideGroupKey"
                    :isServerSideGroup="isServerSideGroup"
                    :localeText="localeText"
                    :masterDetail="masterDetail"
                    :multiSortKey="multiSortKey"
                    :pagination="pagination"
                    :paginationPageSize="pageSize"
                    :rowData="rowData"
                    :rowHeight="rowHeight"
                    :cacheBlockSize="pageSize"

                    :rowModelType="rowModelType"
                    :serverSideDatasource="createDatasource?createDatasource:createLocalDatasource"
                    :sideBar="sideBar"
                    :suppressPaginationPanel="suppressPaginationPanel"
                    :suppressRowHoverHighlight="suppressRowHoverHighlight"
                    :treeData="treeData"
                    colResizeDefault='shift'
                    style="width: 100%; height: 100%;"
                    @gridReady="onGridReady"
                    @paginationChanged="onPaginationChanged"
                >

                </ag-grid-vue>
            </div>
            <div class="card-footer ">
            </div>
        </div>
        <div v-else :key="aggridKey">
            <ag-grid-vue
                :id="id"
                :autoHeight="autoHeight"
                :class="className"
                :columnDefs="colonnes"
                :detailCellRenderer="detailCellRenderer"
                :detailCellRendererParams="detailCellRendererParams"
                :detailRowAutoHeight="detailRowAutoHeight"
                :domLayout="domLayout"
                :excelStyles="excelStyles"
                :getRowId="getRowId"
                :getRowStyle="getRowStyle"
                :localeText="localeText"
                :masterDetail="masterDetail"
                :multiSortKey="multiSortKey"
                :pagination="pagination"
                :paginationPageSize="pageSize"
                :cacheBlockSize="pageSize"
                :rowData="rowData"
                :rowHeight="rowHeight"
                :rowModelType="rowModelType"
                :serverSideDatasource="createDatasource?createDatasource:createLocalDatasource"
                :sideBar="sideBar"
                :suppressPaginationPanel="suppressPaginationPanel"
                :suppressRowHoverHighlight="suppressRowHoverHighlight"
                colResizeDefault='shift'
                style="width: 100%; height: 100%;"
                @gridReady="onGridReady"

                @paginationChanged="onPaginationChanged"
            >

            </ag-grid-vue>
        </div>
    </b-overlay>

</template>

<script>
import 'ag-grid-enterprise';
import {LicenseManager} from 'ag-grid-enterprise'
LicenseManager.setLicenseKey("For_Production-Valid_Until-22_May_2024_[v29]_MTYyMTYzODAwMDAwMA==edd463c08734ce415741e4c2b8ade1f5")
import {AgGridVue} from "ag-grid-vue";
import AG_GRID_LOCALE_FR from "@/components/agGridLocalFr.js";
//
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import AgGridBtnClicked from "@/components/AgGridBtnClicked.vue"
export default {
    name: "AgGridTable",

    components: {AgGridVue,AgGridBtnClicked},
    props: {
        inCard: {
            default: true
        },
        id: {},
        tableKey: {},
        autoHeight: {
            default: false
        },
        autoGroupColumnDef: {
            default: false
        },
        treeData: {
            default: false
        },
        isServerSideGroup: {
            default: false
        },

        suppressRowHoverHighlight: {
            default: false
        },
        getRowStyle: {
            default: false
        },
        getServerSideGroupKey: {
            default: false
        },
        primary: {
            default: 'id'
        },
        localeText: {
            default: () => AG_GRID_LOCALE_FR
        },
        rowHeight: {
            default: "30"
        },
        suppressPaginationPanel: {
            default: false
        },
        extrasData: {
            default: () => []
        },
        showPagination: {
            default: true
        },
        showExport: {
            default: true
        },
        detailRowAutoHeight: {
            default: true
        },
        showActu: {
            default: true
        },
        showDeleteButton: {
            default: true
        },
        masterDetail: {
            default: true
        },
        detailCellRendererParams: {
            default: null
        },
        detailCellRenderer: {
            default: null
        },
        showCardHeader: {
            default: true
        },
        sideBar: {
            default: () => {
                return {
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                            minWidth: 225,
                            maxWidth: 225,
                            width: 225,
                            toolPanelParams: {
                                suppressRowGroups: true,
                                suppressValues: true,
                                suppressPivots: true,
                                suppressPivotMode: true,
                                suppressColumnFilter: true,
                                suppressColumnSelectAll: true,
                                suppressColumnExpandAll: true,
                            },
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                            minWidth: 180,
                            maxWidth: 400,
                            width: 250,
                            toolPanelParams: {
                                suppressRowGroups: true,
                                suppressValues: true,
                                suppressPivots: true,
                                suppressPivotMode: true,
                                suppressColumnFilter: true,
                                suppressColumnSelectAll: true,
                                suppressColumnExpandAll: true,
                            },
                        }
                    ],
                    position: 'left',
                    defaultToolPanel: ['filters', 'columns']

                }
            }
        },
        domLayout: {
            default: 'autoHeight'
        },
        rowSelection: {
            default: 'multiple'
        },
        className: {
            default: 'ag-theme-alpine'
        },
        columnDefs: {
            default: null
        },
        rowData: {
            default: null
        },
        defaultColumnsOrder: {
            default: () => []
        },
        sortModelsData: {
            default: () => []
        },


        rowModelType: {
            default: 'serverSide'
        },
        multiSortKey: {
            default: true

        },
        pagination: {
            default: true
        },
        paginationPageSize: {
            default: 25
        },
        cacheBlockSize: {
            default: 10000
        },
        maxBlocksInCache: {
            default: 1
        },
        url: {
            default: ""
        },
        createDatasource: {
            default: null
        },
        excelStyles: {
            default: [
                // The base style, red font.
                {
                    id: "redFont",
                    font: {
                        color: '#ff0000',
                    },
                },
                // The cellClassStyle: background is green and font color is light green,
                // note that since this excel style it's defined after redFont
                // it will override the red font color obtained through cellClass:'red'
                {
                    id: "greenBackground",
                    alignment: {
                        horizontal: 'Right', vertical: 'Bottom'
                    },
                    font: {color: "#e0ffc1"},
                    interior: {
                        color: "#008000", pattern: 'Solid'
                    }
                },
                {
                    id: "orangeBackground",
                    alignment: {
                        horizontal: 'Right', vertical: 'Bottom'
                    },
                    font: {color: "#e0ffc1"},
                    interior: {
                        color: "#ffa700", pattern: 'Solid'
                    }
                },
                {
                    id: "cell",
                    alignment: {
                        vertical: "Center"
                    }
                }
            ]
        }
    },
    data() {
        return {
            actualFiltre: 0,
            dataCount: 0,
            isLoading: true,
            actualDataCount: 1,
            pageSize: 25,
            gridApi: null,
            columnApi: null,
            getRowId: null,
            showDelete: false,
            baseFilter: [],
            filtreEnteteData: {},
            filtreEnteteKeys: 0,
            selectElement: [],
            dynamiqueFilter: [],
          aggridKey:0
        }
    },

    created() {
        this.pageSize = this.paginationPageSize
        this.getRowId = (params) => {
            return params.data[this.primary];
        };
    },
    mounted() {
        console.log('voici les donnees monter', this.filtreEntete)
        this.filtreEntete.forEach(data => {
            this.getData(data.filterParams.url)
        })
    },
    computed: {
        $routeData: function () {
            let router = {meta: {}};
            try {
                router = window.routeData
            } catch (e) {
            }
            ;
            return router;
        },
        colonnes: function () {
            let colonnes = this.columnDefs.map(function (data) {
                if (!Object.keys(data).includes('resizable')) {
                    data['resizable'] = true
                }
                data['wrapHeaderText'] = true
                return data
            })
            colonnes = colonnes.filter(data => data.filter != 'FiltreEntete')


            const objetsOrdonnes = colonnes.sort(this.customSortColumns);
            console.log('voici le tableau ordonnnes', objetsOrdonnes, this.defaultColumnsOrder)
            return objetsOrdonnes;
        },
        jointures: function () {
            let jointures = [];
            this.columnDefs.forEach((data) => {
                if (Object.keys(data).includes('join')) {
                    jointures.push(data['join'])
                }
            })
            return jointures;
        },
        fields: function () {
            let field = [];
            this.columnDefs.forEach((data) => {
                if (Object.keys(data).includes('field')) {
                    field.push(data['field'])
                }
            })
            return field;
        },
        filtreEntete: function () {
            let index = this.filtreEnteteKeys
            let colonnes = this.columnDefs.map(function (data) {
                if (!Object.keys(data).includes('resizable')) {
                    data['resizable'] = true
                }
                data['wrapHeaderText'] = true
                return data
            })
            colonnes = colonnes.filter(data => data.filter == 'FiltreEntete')
            colonnes = colonnes.map(data => {
                try {
                    console.log('monter je recupere les data', this.filtreEnteteData)
                    data['donnees'] = this.filtreEnteteData[data.filterParams.url].sort(function (a, b) {
                        const nameA = a.libelle.toUpperCase();
                        const nameB = b.libelle.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                    })
                } catch (e) {

                }
                return data
            })
            return colonnes;
        },
        style: function () {
            return `width:100%;`
        },
        createLocalDatasource: server => {
            return {
                getRows: params => {
                    server.isLoading = true;
                    console.log('voici les donnees de routages ==>', server.routeData());

                    let filter = {};
                    try {
                        filter = server.$route.meta.filter;
                    } catch (e) {
                        console.error('Erreur en accédant à routeData meta filter:', e);
                    }

                    let filter1 = [];
                    let filter2 = [];
                    try {
                        filter1 = server.baseFilter;
                    } catch (e) {
                        console.error('Erreur en accédant à baseFilter:', e);
                    }
                    try {
                        filter2 = server.dynamiqueFilter;
                    } catch (e) {
                        console.error('Erreur en accédant à dynamiqueFilter:', e);
                    }

                    const filter3 = filter1.concat(filter2);

                    // Nettoyage des anciens filtres
                    server.filtreEntete.forEach(data => {
                        try {
                            delete params.request.filterModel[data.field];
                        } catch (e) {
                            console.error('Erreur en nettoyant le filterModel:', e);
                        }
                    });

                    // Ajout des filtres définis par les customFilter
                    const filtreUlterieurKeys = Object.keys(server.gridApi).filter(data => data.startsWith('__extraFilter__'));
                    filtreUlterieurKeys.forEach(data => {
                        const filtre = server.gridApi[data];
                        console.log('voila les data de laggrid filtre', filtre);
                        try {
                            params.request.filterModel[filtre.keys] = filtre.values;
                        } catch (e) {
                            console.error('Erreur en ajoutant les filtres supplémentaires:', e);
                        }
                    });

                    filter3.forEach(data => {
                        try {
                            params.request.filterModel[data.champ] = data;
                        } catch (e) {
                            console.error('Erreur en ajoutant les filtres composés:', e);
                        }
                    });

                    console.log('voici les filtres', filter3, server.filtreEntete, filtreUlterieurKeys, params.request.filterModel);
                    console.log('voila le filter model', params.request.filterModel);
                    console.log('voila les data de laggrid getModel', filtreUlterieurKeys);

                    params.request['__filter__'] = filter;
                    params.request['__extras__'] = server.extrasData;
                    params.request['__showDelete__'] = server.showDelete;
                    params.request['__jointures__'] = server.jointures;
                    params.request['__champs__'] = server.fields;
                    // params.request['sortModels'] = sortModelsData;

                    console.log('voici les data', params);

                    server.axios.post(server.url, params.request)
                        .then(response => {
                            try {
                                server.dataCount = response.data.rowCount;
                            } catch (e) {
                                console.error('Erreur en accédant à rowCount:', e);
                            }
                            server.$emit('newData', response.data);
                            params.success(response.data);
                            server.isLoading = false;
                        })
                        .catch(error => {
                            console.error('Erreur lors de l\'appel API:', error);
                            server.isLoading = false;
                            params.fail();
                        });
                }
            };
        }

    },
    methods: {
        customSortColumns(a, b) {
            let cles = this.defaultColumnsOrder
            const indexA = cles.indexOf(a.field);
            const indexB = cles.indexOf(b.field);

            // Si les deux objets sont dans la liste des clés, les comparer par leur index dans les clés
            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
            }
            // Si l'un des objets est dans la liste des clés, il doit venir avant celui qui n'y est pas
            if (indexA !== -1) return -1;
            if (indexB !== -1) return 1;
            // Si aucun des objets n'est dans la liste des clés, conserver leur ordre initial
            return 0;
        },
        toggleFiltre(filtre) {
            if (this.actualFiltre == filtre) {
                this.actualFiltre = ''
            } else {
                this.actualFiltre = filtre
            }
        },
        getNameForFiltre(filtre) {
            let total = 0
            this.dynamiqueFilter.forEach(data => {
                if (data.champ == filtre.field) {
                    try {
                        total = data.values.length
                    } catch (e) {

                    }
                }
            });
            let libelle = [filtre.headerName.toUpperCase()]

            return libelle.join(' ')
        },
        getTotalSelectForFiltre(filtre) {
            let total = 0
            this.dynamiqueFilter.forEach(data => {
                if (data.champ == filtre.field) {
                    try {
                        total = data.values.length
                    } catch (e) {

                    }
                }
            });
            let libelle = [filtre.headerName.toUpperCase()]
            if (total > 0) {
                libelle.push(`( ${total} )`)
            }

            return libelle.join(' ')
        },
        isSelectElementEntete(element, id) {
            return this.selectElement.includes(element + "-" + id)

        },
        toggleElementEntete(element, id) {
            let cle = element + "-" + id
            console.log('toggle Element Debut', cle, this.selectElement)
            if (this.selectElement.includes(cle)) {
                this.selectElement = this.selectElement.filter(data => data != cle)
            } else {
                this.selectElement.push(cle)
            }
            this.dynamiqueFilter = this.getEnteteParamsForRequest()
            this.redraw()

        },
        getEnteteParamsForRequest() {
            let data = {}
            let filtres = []
            this.selectElement.forEach(donnes => {
                let cle = donnes.split('-')[0]
                let valeur = parseInt(donnes.split('-')[1])
                if (!Object.keys(data).includes(cle)) {
                    data[cle] = []
                }
                data[cle].push(valeur)
            })
            Object.keys(data).forEach(dat => {
                let _dat = {
                    filterType: 'set',
                    champ: dat,
                    values: data[dat],
                }
                filtres.push(_dat)
            })

            return filtres

        },
        getData(route) {
            let agGridParams = {
                "startRow": 0,
                "endRow": 100,
                "rowGroupCols": [],
                "valueCols": [],
                "pivotCols": [],
                "pivotMode": false,
                "groupKeys": [],
                "filterModel": {},
                "sortModel": [],
                "__filter__": [],
                "__extras__": {}
            }
            console.log('voici les donnees monter', route)
            this.axios.post(route, agGridParams).then(response => {
                let data = []
                try {
                    data = response.data.rowData
                } catch (e) {
                    data = []
                }
                this.filtreEnteteData[route] = data
                this.filtreEnteteData = {...this.filtreEnteteData}
            })

        },
        routeData() {
            let router = {meta: {}};
            try {
                router = window.routeData
            } catch (e) {
            }
            ;
            return router;
        },
        onBtExport() {
            this.gridApi.exportDataAsExcel();
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
            this.isLoading = false
            const allColumnIds = [];

            // this.gridApi.autoSizeAllColumns();
            this.$emit('gridReady', params)
        },
        change() {
            let instance = this.gridApi.getFilterInstance('name')
            instance.setFilterValues([
                {
                    name: 'France',
                    code: 'FR',
                },
                {
                    name: 'Australia',
                    code: 'AU',
                },
            ]);
            instance.applyModel();
            this.gridApi.onFilterChanged();
        },
        onPaginationChanged(params) {
        },
        getSelectedRows() {
            const selectedNodes = this.gridApi.getSelectedNodes();
            const selectedData = selectedNodes.map(node => node.data);
            const selectedDataStringPresentation = selectedData.map(data => `${data.make} ${data.model}`).join(', ');
            alert(`Selected nodes: ${selectedDataStringPresentation}`);
        },
        redraw() {
            this.gridApi.refreshServerSide()
        },
        toggleDelete() {
            this.showDelete = !this.showDelete
            this.redraw()
        }
    },
    watch: {
        'pageSize': {
            handler: function (after, before) {
              this.aggridKey++


            },
        }
    }
}
</script>

<style scoped>

.elementFiltre {
    padding: 5px;
    margin: 5px;
    border: 2px dashed #389ccc;
    border-radius: 5px;
}

.allBoutons {
    display: flex;
    gap: 10px
}

.ag-root-wrapper {
    border-radius: 5px
}

.elementFiltre {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3px;
}

.BoutonsEntete {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3px;
}
</style>

